<!--
 * @Author: your name
 * @Date: 2021-09-23 10:01:44
 * @LastEditTime: 2021-11-26 10:14:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \前台代码c:\Users\qiutf\Desktop\新建文件夹 (3)\src\views\profile\index.vue
-->
<template>
  <el-container v-if="show" class="hy-container">
    <el-container>
      <el-main>
        <!-- <Main></Main> -->
        <router-view></router-view>
      </el-main>
      <el-footer height="48px">
        <Footer />
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "MainPage",
  props: {},
  data() {
    return {
      show: false,
    };
  },
  computed: {},
  watch: {},
  components: {
    Footer: () => import("@/components/public/footer"),
  },
  created() {
    setTimeout(() => {
      this.show = true;
    }, 100);
  },
  mounted() { },
  beforeDestroy() { },
};
</script>

<style lang='scss' scoped>
.hy-container {
  height: 100%;
}
.el-aside {
  border: 1px solid #ddd;
  overflow: hidden !important;
}
.el-main {
  padding-bottom: 0;
}
</style>