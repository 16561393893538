<template>
  <el-container v-if="show" class="hy-container">
    <el-main>
      <!-- <Main></Main> -->
      <router-view></router-view>
    </el-main>
    <el-footer height="48px">
      <Footer />
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: "MainPage",
  props: {},
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      // const h = this.$createElement;
      // this.$msgbox({
      //   title: '绑定提示',
      //   message: h('p', null, [
      //     h('span', null, '检测到您并未绑定手机号，为不影响正常是用，建议您前往'),
      //     h('i', { style: 'color: teal' }, '单位管理 -> 企业管理 -> 绑定手机'),
      //     h('span', null, ' 完成绑定')
      //   ]),
      //   showCancelButton: true,
      //   confirmButtonText: '立即前往',
      //   cancelButtonText: '稍后再说',
      //   type: 'warning'
      // }).then(() => {
      //   setTimeout(() => {
      //     this.$router.push({ name: 'enterpriseAbout' })
      //   }, 0);
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: '为确保您正常使用后台管理功能，请记得绑定手机号'
      //   });
      // });
    },
  },
  components: {
    Footer: () => import("@/components/public/footer"),
  },
  created() {
    setTimeout(() => {
      this.show = true;
    }, 100);
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.hy-container {
  height: 100%;
}

.el-aside {
  border: 1px solid #ddd;
  overflow: hidden !important;
}

.el-main {
  padding: 0;
}
</style>
