<template>
  <el-container class="hy-container">
    <!-- header -->
    <el-header :height="headerHeight">
      <Header v-if="!headerChange"></Header>
      <ResourceHead v-else></ResourceHead>
    </el-header>

    <!-- body  (menu and main) -->
    <el-container>
      <!-- menu -->
      <el-aside :width="autoWidth" v-if="showAside()">
        <SubMenu></SubMenu>
      </el-aside>

      <!-- main -->
      <el-container class="min-width">
        <!-- <transition name="slide-fade"> -->
        <!-- 增加一个key，防止相同父级路由下的页面元素复用而没有transition效果 -->
        <!-- <router-view :key="Math.random()"></router-view> -->
        <router-view></router-view>
        <!-- </transition> -->
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: "MainPage",
  props: {},
  data() {
    return {
      headerHeight: "70px",
    };
  },
  computed: {
    // 由于aside自带宽度，在这里只好根据menu菜单的展开或隐藏来改变其宽度
    autoWidth() {
      let flag = this.$store.getters.get_menuNavFlag;
      let w;
      let openWidth = this.$lang === 'zh-CN' ? '200px' : '340px'
      w = (flag ? '80px' : openWidth);
      return w;
    },

    headerChange() {
      let n = this.$store.getters.get_edit;
      if (n) this.headerHeight = "70px";
      else this.headerHeight = "60px";
      return n;
    },

    userinfo() {
      //新开的页面vuex里面没有内容
      return (
        this.$store.getters.get_userinfo ||
        JSON.parse(localStorage.getItem("userinfo"))
      );
    },
  },
  methods: {
    showAside() {
      let userinfo = this.userinfo;
      if (!userinfo) {
        // this.$message.error('读取用户信息失败，请重新登录')
        return this.$router.replace({ name: "login" });
      }
      //游离用户不显示，block用户不显示
      // update:2023-11-02   tenantId改为判断 sysOrg
      if (!userinfo.sysOrg && userinfo.userType == 999) return true;
      else if (!userinfo.sysOrg) return false;
      else if (userinfo.status == 2) return false;
      return true;
    },
  },
  components: {
    Header: () => import("@/components/public/header"),
    SubMenu: () => import("@/components/public/navMenu"),
    ResourceHead: () => import("@/components/public/resourcePageHead"),
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.el-header {
  padding: 0;
  border-bottom: 1px solid #e3e3e3;
}
.hy-container {
  height: 100%;
}
.el-aside {
  overflow: hidden !important;

  transition: all 0.8s;
}
.min-width {
  min-width: 1200px;
  background-color: #f4f5fa;
}
</style>
